<template>
  <div class="container">
    <Header></Header>

    <div class="ny-main">
      <h3 class="ny-title text-center">{{model.name}}</h3>

      <div class="ny-con">
        <div class="pro-text">
          <p v-html="model.content"></p>
        </div>

        <viewer :images="model.imgs" class="pro-detail-list">
            <!-- photo 一定要一个数组，否则报错 -->
            <img v-for="(item, index) in model.imgs" :src="item.file_url" :key="index" v-proportion="0.6875" />
        </viewer>

      </div>
    </div>
    <!-- main end  -->

    
    <Footer></Footer>
    
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import { info } from '@/api/project'
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
	  model: {
	    imgs: [],
	  },
    };
  },
  mounted() {
     this.$AOS.init({

        offset: 200,  
        duration: 600,  
        easing: 'ease-in-sine',  
        delay: 100

      });
      
  },
  created() {
    this.info()
  },
  watch: {
    'model.imgs'(newValue, oldValue) {
	}
  },
  methods: {
    info(){
		this.loading = true
        info({id:this.$route.query.id}).then(res => {
          this.model = res.data.data
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
	}   
  }
};
</script>

<style scoped>

.ny-main {
  overflow: hidden;
  padding: 50px 8.3vw;
}

.ny-title {
  color: #0a3c5b;
  line-height: 1;
  /*font-size: 2.1667vw;*/
  font-size: 4.1667vw;
  font-family: DINCound;
  font-weight: normal;
}

.ny-con {
  width: 100%;
  margin-top: 35px;
}

.pro-text p {
  line-height: 36px;
  font-size: 18px;
  color: #000;
  margin-bottom: 10px;

}

.pro-detail-list{
  width: 100%;
  overflow: hidden;
  /* display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; */

}

.pro-detail-list img{
  box-sizing: border-box;
  width: 25%;
  border: 1px solid #fff;
  cursor: pointer;
  float: left;
}



</style>

<style>
.viewer-toolbar{
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
}

.viewer-toolbar > ul{
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  height: 60px;
  margin: 0;
}

.viewer-toolbar > ul > .viewer-large {
    height: 60px;
    margin-bottom: 0px;
    margin-top: 0px;
    width: 30px;
}

.viewer-toolbar ul li{
  position: absolute;
  float: none;
  width: 30px;
  height: 60px;
  margin: 0;
  text-align: center;
  line-height: 60px;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0.7)
}

.viewer-toolbar ul li:hover{
  background-color: rgba(0, 0, 0, 1)
}

.viewer-toolbar ul li:first-child{
  left: 10%;
}

.viewer-toolbar ul li:last-child{
  right: 10%;
}

.viewer-toolbar > ul > li:focus{
  position: absolute;
}

.viewer-toolbar > ul > .viewer-large::before {
    margin: 20px 5px;
}

.viewer-container{
  background: rgba(0, 0, 0, 0.6);
}

</style>



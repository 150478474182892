import request from '@/utils/request'
const url = '/api/project/'
// ----------------内容管理----------------
/**
 * 内容提交
 * @param {array} params 请求参数
 */
export function add(data) {
  return request({
    url: url + 'add',
    method: 'post',
    data
  })
}
/**
 * 列表
 * @param {array} params 请求参数
 */
export function list(params) {
  return request({
    url: url + 'list',
    method: 'get',
    params: params
  })
}
/**
 * 文件添加
 */
export function fileadd() {
  return process.env.VUE_APP_BASE_API + '/admin/file.File/add'
}
/**
 * 搜索列表
 * @param {array} params 请求参数
 */
export function search_list(params) {
  return request({
    url: url + 'search_list',
    method: 'get',
    params: params
  })
}
/**
 * 搜索内容
 * @param {array} params 请求参数
 */
export function info(params) {
  return request({
    url: url + 'info',
    method: 'get',
    params: params
  })
}